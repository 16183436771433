import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "title": "Verify Custom UI css features",
  "category": "Customization"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`CSS is compiled using `}<a parentName="p" {...{
        "href": "https://postcss.org/"
      }}>{`PostCSS`}</a>{` with the following plugins enabled:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/postcss/autoprefixer"
        }}>{`Autoprefixer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://preset-env.cssdb.org/"
        }}>{`PostCSS Preset Env`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.npmjs.com/package/postcss-nesting"
        }}>{`PostCSS Nesting`}</a>{` (not to be confused with PostCSS Nested)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      